import { useState } from 'react';
import useEventListener from './useEventListener';
import { kibanaLog } from '../helpers/logger';
import { flagEnabled } from '../helpers/featureFlags';
// import { logger } from '../helpers/logger';

const scripts = {};

const INIT_STATE = 0;
const READY_STATE = 1;
const ERROR_STATE = 2;

// const c = logger('useScript').enable(false);

const stateForScript = (uri, state) => {
  const src = uri.toString();
  return (oldState) => {
    if (!(src in scripts) && !oldState) {
      // c.log(`Creating script element for ${src}`);
      scripts[src] = {
        element: Object.assign(
          document.createElement('script'),
          {
            type: 'text/javascript',
            src: src.toString(),
          },
        ),
        state,
      };
      document.head.appendChild(scripts[src].element);

      if (flagEnabled('enhancedCpcLoggingEnabled')) {
        kibanaLog('cpc_script_appended');
      }
    } else if (state !== INIT_STATE) {
      // c.log(`Updating state of ${src}: ${state}`);
      scripts[src] = { ...oldState, state };
    }
    return scripts[src];
  };
};

/**
 * Inserts a new script bundle into the document's <head> exactly once per page load
 * @param {string|URL} src URL of script bundle
 * @returns {[loading: boolean, error: boolean]} a tuple indicating the script's load state.
 */
export default function useScript(src) {
  const [state, setState] = useState(stateForScript(src, INIT_STATE)());
  useEventListener(state.element, 'load', () => {
    // c.log(`${src} loaded; updating to READY_STATE`);
    if (flagEnabled('enhancedCpcLoggingEnabled')) {
      kibanaLog('cpc_script_load_event_handled');
    }
    setState(stateForScript(src, READY_STATE));
  }, [src]);
  useEventListener(state.element, 'error', () => {
    // c.log(`${src} load failed; updating to ERROR_STATE`);
    setState(stateForScript(src, ERROR_STATE));
  }, [src]);
  return [state.state === INIT_STATE, state.state === ERROR_STATE];
}

import { useContext } from 'react';
import { CpcContext } from './useCpc';
import { flagEnabled } from '../helpers/featureFlags';
import { kibanaLog } from '../helpers/logger';

function useCpcDispatcher(finalChannelData, jumpShadow, cpcPageType, setLoading) {
  const cpc = useContext(CpcContext);

  if (!jumpShadow) return null;

  const dispatch = (message, cpcSessionId) => {
    const action = { ...message, channelData: finalChannelData };

    // Dispatch a message to the CPC as described in the integration docs
    // see https://etwiki.sys.comcast.net/pages/viewpage.action?spaceKey=XIA&title=Common+Payment+Component+%28CPC%29+Integration+Guide
    jumpShadow.querySelector('iframe').contentWindow.postMessage(JSON.stringify(action), '*');
    // The CPC will not display until something upstream sends a 'CPC_CONFIG_SUBMIT' message,
    //   and then there's some load time which we can't listen for (as it's within the component's
    //   iframe).  So set loading to false on a timeout and hope it's done by then.
    if (message.action === 'CPC_CONFIG_SUBMIT') {
      if (flagEnabled('enhancedCpcLoggingEnabled')) {
        kibanaLog('cpc_config_submitted', { cpcSessionId });
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const submitForm = () => new Promise((resolve, reject) => {
    const onceListener = ({ data }) => {
      // Parse the message data; if it doesn't parse, ignore this message.
      let dataObj;
      try {
        dataObj = JSON.parse(data);
      } catch (e) {
        return;
      }
      const { action, ...rest } = dataObj;
      if (action === 'CPC_FORM_SUBMIT_RESPONSE') {
        window.removeEventListener('message', onceListener);
        resolve(rest);
      }
      if (action === 'CPC_FORM_ERROR' || action === 'CPC_ERROR') {
        window.removeEventListener('message', onceListener);
        reject(rest);
      }
    };
    window.addEventListener('message', onceListener);
    // c.log('[JumpComp]', 'Submitting form');
    dispatch({
      action: 'CPC_FORM_SUBMIT',
      channel: 'jump-iframe',
      cpcPageType,
    });
  });

  cpc.current = {
    // Make submitForm available from context
    submitForm,
  };

  // make dispatch and submitForm available to Jump component
  return { dispatch, submitForm };
}

export default useCpcDispatcher;
